import { inject, Injectable } from '@angular/core'
import { Route, PreloadingStrategy, Router } from '@angular/router'
import { Observable, of } from 'rxjs'
import { routes } from '@auth/auth.routes'

@Injectable({
  providedIn: 'root'
})
export class CustomPreloadingStrategy implements PreloadingStrategy {
  router = inject(Router)

  preload(route: Route, load: () => Observable<unknown>): Observable<unknown> {
    const authRoutes: string[] = routes.map(route => route.path!)
    const currentRoutePath = this.router.url.split('/')[1]

    if (authRoutes.includes(currentRoutePath)) return of(null)
    return route.data?.['preload'] === true ? load() : of(null)
  }
}
