import { ChangeDetectionStrategy, Component, inject } from '@angular/core'
import { NotificationComponent } from '../notification/notification.component'
import { NotificationsService } from '@notifications/services/notifications.service'

@Component({
  selector: 'app-notification-list',
  standalone: true,
  imports: [NotificationComponent],
  templateUrl: './notification-list.component.html',
  styleUrl: './notification-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationListComponent {
  notificationService = inject(NotificationsService)

  notifications = this.notificationService.notifications
}
