import { inject } from '@angular/core'
import { CanActivateFn, Router } from '@angular/router'
import { UserService } from '@participant/services/user.service'

export const isUserUnauthenticatedGuard: CanActivateFn = async () => {
  const userService = inject(UserService)
  const router = inject(Router)

  await userService.getUser()

  if (!userService.isLoggedIn()) return true

  return router.navigateByUrl('/')
}
