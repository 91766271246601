<button
  (click)="submitSSO()"
  class="button1 w-full flex gap-2 items-center text-center"
  type="submit">
  <img
    class="w-10 h-10"
    src="https://superlikers-themes.s3.amazonaws.com/ppmu/iconabi.png"
    alt="SSO Logo" />
  Iniciar sesión con ABInBev (SSO)
</button>
