import { ComponentType } from '@angular/cdk/portal'
import { Notification } from '@notifications/models/notifications'

export const notificationComponentMap: Record<string, () => Promise<ComponentType<unknown>>> = {
  image: () => import('../modals/image-modal/image-modal.component').then(m => m.ImageModalComponent),
  video: () => import('../modals/video-modal/video-modal.component').then(m => m.VideoModalComponent)
}

export const getModalComponent = async (notification: Notification) => {
  const { category, type } = notification

  const loadComponent = notificationComponentMap[category] || notificationComponentMap[type!]
  if (loadComponent) return await loadComponent()

  return
}
