import { Control } from 'superlikers-dynamic-forms'

export const signupControls: Control[] = [
  {
    id: 1,
    label: 'Nombre Completo',
    name: 'nombre-completo',
    controlType: 'input',
    type: 'text',
    placeholder: 'Nombre Completo',
    validators: {
      required: true
    }
  },
  {
    id: 2,
    label: 'Número del Cliente',
    name: 'codigo-de-cliente',
    controlType: 'input',
    type: 'text',
    placeholder: 'Código de Cliente',
    validators: {
      required: true
    }
  },
  {
    id: 3,
    label: 'Email',
    name: 'email',
    controlType: 'input',
    helpText: 'Correo electrónico que más usas',
    type: 'email',
    placeholder: 'Correo electrónico',
    validators: {
      required: true,
      email: true
    }
  },
  {
    id: 4,
    label: 'Contraseña',
    name: 'password',
    controlType: 'input',
    type: 'password',
    validators: {
      required: true
    }
  },
  {
    id: 5,
    label: 'Confirmar Contraseña',
    name: 'password_confirm',
    controlType: 'input',
    type: 'password',
    validators: {
      required: true
    }
  }
]
