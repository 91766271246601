import { ApplicationConfig, LOCALE_ID, provideZoneChangeDetection } from '@angular/core'
import { provideRouter, withComponentInputBinding, withPreloading } from '@angular/router'
import { provideAnimations } from '@angular/platform-browser/animations'
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http'
import { API_BASE_URL, headersInterceptor } from 'superlikers-microsites-api'
import { ERROR_MESSAGES_ES, VALIDATION_ERROR_MESSAGES } from 'superlikers-dynamic-forms'
import { CustomPreloadingStrategy } from '@core/custom-preloading-strategy'

import { routes } from './app.routes'
import { environment } from '../environments/environment'

import { registerLocaleData } from '@angular/common'
import localeEs from '@angular/common/locales/es'

registerLocaleData(localeEs)

import { register } from 'swiper/element/bundle'
register()

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes, withComponentInputBinding(), withPreloading(CustomPreloadingStrategy)),
    provideAnimations(),
    provideHttpClient(withFetch(), withInterceptors([headersInterceptor])),
    {
      provide: API_BASE_URL,
      useValue: environment.baseUrl
    },
    {
      provide: VALIDATION_ERROR_MESSAGES,
      useFactory: () => ERROR_MESSAGES_ES
    },
    { provide: LOCALE_ID, useValue: 'es' }
  ]
}
