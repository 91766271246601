<div class="relative text-theme-darkBlue bg-theme-gradient-light-blue p-4">
  <img
    (click)="closeModal()"
    class="absolute top-4 right-4 cursor-pointer"
    src="https://superlikers-themes.s3.amazonaws.com/modelo_para_ganar/v4/icon-blue-ligth-cancel.svg"
    alt="close Modal" />

  <img
    class="w-56 m-auto max-w-full"
    src="https://superlikers-themes.s3.amazonaws.com/modelo_para_ganar/modales/ic_soporte-01.png"
    alt="Soporte" />

  <div class="my-4 text-center">
    <h2 class="font-bold text-theme-darkBlue text-lg">Bienvenido empresario</h2>
    <p class="font-light text-theme-mediumBlue1">Completa la información para continuar</p>
  </div>

  <form
    class="flex flex-col gap-4 w-full"
    autocomplete="off"
    [formGroup]="form"
    (ngSubmit)="submit()">
    <div class="p-6.5 flex flex-col gap-6">
      @for (control of controls; track control.name) {
        <ng-container
          [ngComponentOutlet]="controlResolver.resolve(control, form) | async"
          [ngComponentOutletInjector]="control.name | controlInjector: control">
        </ng-container>
      }
    </div>

    <button
      class="button1"
      type="submit">
      Enviar
    </button>
  </form>
</div>
