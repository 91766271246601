import { DialogRef } from '@angular/cdk/dialog'
import { AsyncPipe, NgComponentOutlet } from '@angular/common'
import { ChangeDetectionStrategy, Component, inject } from '@angular/core'
import { FormGroup, ReactiveFormsModule } from '@angular/forms'

import { Control, ControlInjector, ControlResolver } from 'superlikers-dynamic-forms'

import { helpForm } from './data'
import { FormspreeService } from '@shared/services/formspree.service'

@Component({
  selector: 'app-help-modal',
  standalone: true,
  imports: [ReactiveFormsModule, ControlInjector, NgComponentOutlet, AsyncPipe],
  templateUrl: './help-modal.component.html',
  styleUrl: './help-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HelpModalComponent {
  dialogRef = inject<DialogRef<string>>(DialogRef<string>)
  controlResolver = inject(ControlResolver)
  formSpreeService = inject(FormspreeService)

  controls: Control[] = helpForm

  form = new FormGroup({})

  async submit() {
    const values = this.form.value as Record<string, never>

    const data = {
      numero_de_cliente: values['numero_de_cliente'],
      email: values['email'],
      tipo_soporte: values['support']['tipo_soporte'],
      tipo_soporte_comment: values['support']['tipo_soporte_comment']
    }

    const response = await this.formSpreeService.sendFormData('mblrljzy', data)

    if (response.ok) {
      this.closeModal()
    }
  }

  closeModal() {
    this.dialogRef.close()
  }
}
