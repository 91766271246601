import { ChangeDetectionStrategy, Component, inject, OnInit, signal } from '@angular/core'
import { AsyncPipe, NgComponentOutlet } from '@angular/common'
import { FormGroup, ReactiveFormsModule } from '@angular/forms'
import { Router } from '@angular/router'
import { Control, ControlInjector, ControlResolver } from 'superlikers-dynamic-forms'

import { UserService } from '@participant/services/user.service'
import { signupControls } from '@auth/data/signupForm'
import { Participant } from '@participant/models/participant'

@Component({
  selector: 'app-complete-signup-page',
  standalone: true,
  imports: [ReactiveFormsModule, ControlInjector, NgComponentOutlet, AsyncPipe],
  templateUrl: './complete-signup-page.component.html',
  styleUrl: './complete-signup-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompleteSignupPageComponent implements OnInit {
  controlResolver = inject(ControlResolver)
  router = inject(Router)

  userService = inject(UserService)
  user = this.userService.user

  form = new FormGroup({})

  controls: Control[] = signupControls

  error = signal<string>('')
  loading = signal<boolean>(false)

  ngOnInit() {
    const user = this.user()
    if (!user) return

    this.controls.forEach(control => {
      const name = control.name as keyof Participant
      control.value = user[name]
    })
  }

  async submit() {
    if (this.form.invalid) return

    const response = await this.userService.updateParticipant(this.form.value)

    if (response.success) {
      this.router.navigateByUrl('/')
    }
  }
}
