import { Dialog } from '@angular/cdk/dialog'
import { computed, effect, inject, Injectable } from '@angular/core'
import { getModalComponent } from '@notifications/data/modals'
import { Notification } from '@notifications/models/notifications'
import { NotificationsService } from './notifications.service'

@Injectable({
  providedIn: 'root'
})
export class NotificationModalService {
  private dialog = inject(Dialog)
  notificationsService = inject(NotificationsService)

  notificationToShow = computed(() => {
    const notifications = this.notificationsService.notifications()

    return notifications.find(n => {
      const nDate = new Date(n.date).getTime()
      const todayDate = new Date().getTime()

      const dateDiff = todayDate - nDate
      return n.auto_show && n.status === 'show' && dateDiff > 0 && dateDiff < 86400000
    })
  })

  constructor() {
    effect(() => {
      const notificationToShow = this.notificationToShow()
      if (!notificationToShow) return

      this.openNotificationModal(notificationToShow)
    })
  }

  async openNotificationModal(notification: Notification, disableClose: boolean = true) {
    const component = await getModalComponent(notification)

    if (component) {
      this.dialog.open(component, {
        autoFocus: true,
        disableClose,
        data: notification,
        panelClass: 'modal-custom'
      })
    }
  }
}
