import { inject } from '@angular/core'
import { CanActivateFn, Router } from '@angular/router'
import { UserService } from '@participant/services/user.service'

export const canAccessBig5: CanActivateFn = async () => {
  const userService = inject(UserService)
  const router = inject(Router)

  if (userService.user()?.big_5 === 'ok') return true

  return router.navigateByUrl('/home')
}
