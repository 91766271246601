import { Control } from 'superlikers-dynamic-forms'

export const resetPasswordControls: Control[] = [
  {
    id: 1,
    name: 'notification_channel',
    label: '',
    controlType: 'radio',
    options: [
      { label: 'Correo electrónico', value: 'email' },
      { label: 'Celular', value: 'cellphone' }
    ],
    validators: {
      required: true
    }
  },
  {
    id: 2,
    name: 'email',
    label: 'Correo electrónico',
    controlType: 'input',
    type: 'email',
    visible: form => {
      return form.get('notification_channel')?.value === 'email'
    },
    validators: {
      required: true,
      email: true
    }
  },
  {
    id: 3,
    name: 'cellphone',
    label: 'Celular',
    controlType: 'input',
    type: 'number',
    visible: form => {
      return form.get('notification_channel')?.value === 'cellphone'
    },
    helpText: 'El código del país +52 se añadirá automáticamente y solo debe ingresarse el número sin este código',
    validators: {
      required: true
    }
  },
  {
    id: 4,
    name: 'uid',
    label: 'ID de usuario',
    controlType: 'input',
    type: 'text',
    validators: {
      required: true
    }
  }
]
