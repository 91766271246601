import { ChangeDetectionStrategy, Component, inject } from '@angular/core'
import { firstValueFrom } from 'rxjs'
import { SessionService } from 'superlikers-microsites-api'

@Component({
  selector: 'app-sso-button',
  standalone: true,
  imports: [],
  templateUrl: './sso-button.component.html',
  styleUrl: './sso-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SsoButtonComponent {
  sessionService = inject(SessionService)

  async submitSSO() {
    const data = { provider: 'azure_ad' }

    const response$ = this.sessionService.loginSSO(data)
    const response = await firstValueFrom(response$)

    if (response.state === 'success') {
      window.location.href = response.data.azure_login_url
    }
  }
}
