import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core'

import { OverlayModule } from '@angular/cdk/overlay'
import { NotificationListComponent } from '../notification-list/notification-list.component'
import { NotificationsService } from '@notifications/services/notifications.service'

@Component({
  selector: 'app-notification-button',
  standalone: true,
  imports: [OverlayModule, NotificationListComponent],
  templateUrl: './notification-button.component.html',
  styleUrl: './notification-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationButtonComponent {
  notificationsService = inject(NotificationsService)
  notifications = this.notificationsService.notifications

  isOpen = this.notificationsService.notificationDropdown

  noReadNotifications = computed(() => {
    const notifications = this.notifications()

    const noRead = notifications.filter(notification => notification.status !== 'read')
    return noRead.length
  })

  toggleDropdown() {
    this.notificationsService.toggleDropdown()
  }
}
