import { inject, Injectable, signal } from '@angular/core'
import { firstValueFrom } from 'rxjs'
import { EntriesService, ExternalFormsService } from 'superlikers-microsites-api'

import { Notification } from '@notifications/models/notifications'

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  externalsFormsService = inject(ExternalFormsService)
  entriesService = inject(EntriesService)

  #notificationsSignal = signal<Notification[]>([])
  notifications = this.#notificationsSignal.asReadonly()

  #notificationDropdownSignal = signal<boolean>(false)
  notificationDropdown = this.#notificationDropdownSignal.asReadonly()

  constructor() {
    this.getNotifications()
  }

  async getNotifications() {
    const CATEGORY = 'notifications'

    const externalForms$ = this.externalsFormsService.getExternalsForms<Partial<Notification>>({ category: CATEGORY })
    const externalForms = await firstValueFrom(externalForms$)

    const notifications = externalForms.data.map(external => {
      const id = external._id
      return { ...external.data, id } as Notification
    })

    this.#notificationsSignal.set(notifications)
  }

  async markAsRead(notification: Notification) {
    const { id, ...rest } = notification

    if (rest.status === 'read') return
    rest.status = 'read'

    const formResponse$ = this.externalsFormsService.updateExternalForm(notification.id, rest)
    const formResponse = await firstValueFrom(formResponse$)

    if (formResponse.state === 'success') {
      const updatedNotification = { id, ...rest } as Notification

      const externalResponse$ = this.entriesService.sendExternal({ event: 'notifications', properties: rest, category: rest.category })
      await firstValueFrom(externalResponse$)

      this.updateNotifications(updatedNotification)
    }
  }

  private updateNotifications(notification: Notification) {
    const notifications = this.notifications()

    const updatedNotifications = notifications.map(n => {
      return notification.id === n.id ? notification : n
    })

    this.#notificationsSignal.set(updatedNotifications)
  }

  toggleDropdown() {
    this.#notificationDropdownSignal.update(value => !value)
  }

  closeDropdown() {
    this.#notificationDropdownSignal.set(false)
  }
}
