/* eslint-disable @typescript-eslint/no-explicit-any */
import { AsyncPipe, NgComponentOutlet } from '@angular/common'
import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core'
import { FormGroup, ReactiveFormsModule } from '@angular/forms'

import { Control, ControlInjector, ControlResolver } from 'superlikers-dynamic-forms'
import { NotificationChannel } from 'superlikers-microsites-api'

import { PasswordService } from '@auth/services/password.service'
import { resetPasswordControls } from './data'

@Component({
  selector: 'app-reset-password-page',
  standalone: true,
  imports: [ReactiveFormsModule, ControlInjector, NgComponentOutlet, AsyncPipe],
  templateUrl: './reset-password-page.component.html',
  styleUrl: './reset-password-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResetPasswordPageComponent {
  passwordService = inject(PasswordService)
  controlResolver = inject(ControlResolver)

  form = new FormGroup({})
  controls: Control[] = resetPasswordControls

  successMessage = signal<string>('')
  errorMessage = signal<string>('')

  loading = signal<boolean>(false)

  async submit() {
    this.successMessage.set('')
    this.errorMessage.set('')

    if (this.form.invalid) return

    this.loading.set(true)

    const values = this.form.value as Record<string, string>
    const { notification_channel, uid } = values

    const channel = notification_channel as NotificationChannel
    const data = { [channel]: values[channel] } as Record<string, string>

    try {
      const response = await this.passwordService.sendToken(channel, data, uid)

      if (response.state === 'success') {
        this.successMessage.set(response.message)
      } else {
        this.errorMessage.set(response.message)
      }
    } catch (err: any) {
      this.errorMessage.set(err.error.message)
    } finally {
      this.loading.set(false)
    }
  }
}
