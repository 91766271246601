import { inject, Injectable } from '@angular/core'
import { firstValueFrom } from 'rxjs'
import { ForgotPasswordService, NotificationChannel } from 'superlikers-microsites-api'

@Injectable({
  providedIn: 'root'
})
export class PasswordService {
  forgotPasswordService = inject(ForgotPasswordService)

  async sendToken(channel: NotificationChannel, data: Record<string, string>, uid: string) {
    const editUrl = 'password/edit'

    const response$ = this.forgotPasswordService.sendToken(channel, data, uid, editUrl)
    const response = await firstValueFrom(response$)

    return response
  }

  async updatePassword(password: string, password_confirmation: string, token: string, channel: NotificationChannel) {
    const response$ = this.forgotPasswordService.updatePassword(password, password_confirmation, token, channel)
    const response = await firstValueFrom(response$)

    return response
  }
}
