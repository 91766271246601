<div
  (click)="openModal()"
  class="cursor-pointer flex flex-col gap-1 w-full transition-colors duration-300 p-2 rounded hover:bg-theme-lightBlue2">
  <div class="self-end flex gap-1 items-center">
    @if (notification().status === 'show') {
      <div class="bg-theme-red rounded-full h-2 w-2"></div>
    }

    <p class="font-bold text-xs">{{ notification().date | date: "d 'de' MMMM 'de' y" : '' : 'es-ES' }}</p>
  </div>
  <p class="self-start font-light text-sm">{{ notification().description }}</p>
</div>
