<section class="h-screen flex items-center">
  <div class="max-w-lg mx-auto p-4 flex flex-col items-center gap-2 w-full">
    <img
      src="https://superlikers-themes.s3.amazonaws.com/modelo_para_ganar/v4/logo-primario.png"
      alt="Modelo para ganar Logo" />

    <section class="flex flex-col gap-4 items-center rounded-xl p-4 bg-theme-gradient-light-blue text-theme-darkBlue font-light">
      <h1 class="text-theme-mediumBlue3 font-bold text-lg">Recupera tu cuenta</h1>
      <p class="text-center">Si conoces tu correo electrónico o celular registrado ingrésalo a continuación</p>

      @if (successMessage()) {
        <p class="text-theme-greenPositive text-sm text-center">{{ successMessage() }}</p>
      }

      @if (errorMessage()) {
        <div class="text-theme-red text-sm text-center">{{ errorMessage() }}</div>
      }

      <form
        class="flex flex-col gap-4 w-full"
        autocomplete="off"
        [formGroup]="form"
        (ngSubmit)="submit()">
        <div class="p-6.5 flex flex-col gap-6">
          @for (control of controls; track control.name) {
            <ng-container
              [ngComponentOutlet]="controlResolver.resolve(control, form) | async"
              [ngComponentOutletInjector]="control.name | controlInjector: control">
            </ng-container>
          }
        </div>

        <button
          class="button1"
          [disabled]="loading()"
          type="submit">
          Continuar
        </button>
      </form>
    </section>
  </div>
</section>
