<button
  (click)="toggleDropdown()"
  type="button"
  class="relative min-w-7 block"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin">
  <img
    class="w-full"
    src="https://superlikers-themes.s3.amazonaws.com/modelo_para_ganar/v4/icon_noti.svg"
    alt="Ícono de notificaciones" />
  <p class="absolute top-1 -right-1.5 rounded-full text-[0.6rem]/none w-4 h-4 grid items-center bg-theme-redNegative">{{ noReadNotifications() }}</p>
</button>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen()"
  cdkConnectedOverlayHasBackdrop
  (backdropClick)="toggleDropdown()">
  <app-notification-list></app-notification-list>
</ng-template>
