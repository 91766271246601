<section class="h-screen flex items-center">
  <div class="max-w-lg mx-auto p-4 flex flex-col items-center gap-2 w-full">
    <img
      src="https://superlikers-themes.s3.amazonaws.com/modelo_para_ganar/v4/logo-primario.png"
      alt="Modelo para ganar Logo" />
    <h1 class="font-bold text-lg">¡Bienvenido/a Modelo Para Ganar!</h1>
    <p class="text-base">Ingresa tus datos:</p>
    <form
      class="flex flex-col gap-4 w-full"
      autocomplete="off"
      [formGroup]="form"
      (ngSubmit)="submit()">
      @if (error()) {
        <div class="text-theme-red text-sm text-center">{{ error() }}</div>
      }

      <div class="p-6.5 flex flex-col gap-6">
        @for (control of controls; track control.name) {
          <ng-container
            [ngComponentOutlet]="controlResolver.resolve(control, form) | async"
            [ngComponentOutletInjector]="control.name | controlInjector: control">
          </ng-container>
        }
      </div>

      <button
        class="button1"
        [disabled]="loading()"
        type="submit">
        Actualizar Información
      </button>
    </form>
  </div>
</section>
