import { Control } from 'superlikers-dynamic-forms'

export const helpForm: Control[] = [
  {
    id: 1,
    label: 'Número de Cliente',
    name: 'numero_de_cliente',
    controlType: 'input',
    type: 'number',
    placeholder: 'Cod. Modelo'
  },
  {
    id: 2,
    label: 'Correo Electrónico',
    name: 'email',
    controlType: 'input',
    type: 'email',
    placeholder: 'Correo electrónico',
    validators: {
      email: true,
      required: true
    }
  },
  {
    id: 3,
    label: 'Tipo de Soporte',
    name: 'support',
    controlType: 'group',
    controls: [
      {
        id: 4,
        label: 'Tipo de Soporte',
        name: 'tipo_soporte',
        controlType: 'select',
        options: [
          { label: 'Elige el Tipo de Soporte', value: '', selected: true, disabled: true },
          { label: 'Problemas de Registro', value: 'Problemas de Registro' },
          { label: 'Renovación Contraseña', value: 'Renovación Contraseña' },
          { label: 'Soprte Rutinas Sporte Autoejecuciones', value: 'Soprte Rutinas Sporte Autoejecuciones' },
          { label: 'Información Bonificaciones', value: 'Información Bonificaciones' },
          { label: 'Información Metas y Avances', value: 'Información Metas y Avances' },
          { label: 'Información General', value: 'Información General' }
        ]
      },
      {
        id: 5,
        label: '',
        name: 'tipo_soporte_comment',
        controlType: 'input',
        type: 'text',
        placeholder: 'Comentario...'
      }
    ]
  }
]
