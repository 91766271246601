import { ChangeDetectionStrategy, Component, inject } from '@angular/core'
import { SidebarService } from '../../services/sidebar.service'
import { NavigationEnd, Router, RouterLink } from '@angular/router'
import { AuthService } from '@auth/services/auth.service'
import { UserService } from '@participant/services/user.service'
import { ClicksDirective } from '@shared/directives/clicks.directive'

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [RouterLink, ClicksDirective],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarComponent {
  sidebarService = inject(SidebarService)
  userService = inject(UserService)
  authService = inject(AuthService)
  router = inject(Router)

  sidebar = this.sidebarService.sidebar
  user = this.userService.user

  navList = [
    {
      image: 'https://modeloparaganar.s3.us-east-2.amazonaws.com/layout/i-home.svg',
      name: 'Home',
      redirect: '/'
    },
    {
      image: 'https://modeloparaganar.s3.us-east-2.amazonaws.com/layout/i-profile.svg',
      name: 'Mi Perfil',
      redirect: '/participant/profile'
    },
    {
      image: 'https://modeloparaganar.s3.us-east-2.amazonaws.com/layout/i-socios.svg',
      name: 'Socios comerciales',
      redirect: '/socios_comerciales'
    },
    {
      image: 'https://modeloparaganar.s3.us-east-2.amazonaws.com/layout/i-referrals.svg',
      name: 'Referidos',
      redirect: '/referral'
    },
    {
      image: 'https://modeloparaganar.s3.us-east-2.amazonaws.com/layout/i-university.svg',
      name: 'Modelorama Escuela',
      redirect: '/modelorama'
    },
    {
      image: 'https://modeloparaganar.s3.us-east-2.amazonaws.com/layout/i-faq.svg',
      name: 'Preguntas frecuentes',
      redirect: '/preguntas_frecuentes'
    },
    {
      image: 'https://modeloparaganar.s3.us-east-2.amazonaws.com/layout/i-university.svg',
      name: 'Términos y condiciones',
      redirect: '/tos'
    }
  ]

  constructor() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.hideSidebar()
      }
    })
  }

  hideSidebar(event?: MouseEvent) {
    if (event && event.target !== event.currentTarget) return
    this.sidebarService.changeSidebar(false)
  }

  async logout() {
    const response = await this.authService.logout()
    if (response.state === 'success') {
      this.router.navigateByUrl('/login')
    }
  }
}
