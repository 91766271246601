import { inject, Injectable } from '@angular/core'
import { firstValueFrom } from 'rxjs'
import { SessionService } from 'superlikers-microsites-api'

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  sessionService = inject(SessionService)

  async logout() {
    const response$ = this.sessionService.logout()
    const response = await firstValueFrom(response$)

    return response
  }

  async checkRequirements() {
    const response$ = this.sessionService.checkRequirements()
    const response = await firstValueFrom(response$)

    return response
  }
}
