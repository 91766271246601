import { Injectable, signal } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  #sidebarSignal = signal<boolean>(false)
  sidebar = this.#sidebarSignal.asReadonly()

  changeSidebar(value: boolean) {
    this.#sidebarSignal.set(value)
  }
}
