<footer class="fixed bottom-0 max-w-4xl mx-auto inset-x-0 bg-theme-lightBlue3 rounded-full p-2 mb-4 flex gap-4 items-center justify-between text-xs md:px-2 md:py-1 z-40">
  <a
    class="text-theme-mediumBlue1 w-full py-1.5 px-2 rounded-3xl cursor-pointer flex flex-col gap-1 items-center opacity-60 transition-all duration-500 ease-in-out hover:opacity-100 md:flex-row md:justify-center"
    routerLink="/home"
    routerLinkActive="bg-theme-gradient-orange !opacity-100"
    appClicks="home - resumen">
    <img
      src="https://superlikers-themes.s3.amazonaws.com/modelo_para_ganar/v4/icon-house.svg"
      alt="Ícono de Resumen" />
    <p>Resumen</p>
  </a>
  <a
    class="text-theme-mediumBlue1 w-full py-1.5 px-2 rounded-3xl cursor-pointer flex flex-col gap-1 items-center opacity-60 transition-all duration-500 ease-in-out hover:opacity-100 md:flex-row md:justify-center"
    routerLink="/bonificaciones"
    routerLinkActive="bg-theme-gradient-orange !opacity-100"
    appClicks="home - bonificaciones">
    <img
      src="https://superlikers-themes.s3.amazonaws.com/modelo_para_ganar/v4/icon-money.svg"
      alt="Ícono de Bonificaciones" />
    <p>Bonificación</p>
  </a>
  <a
    class="text-theme-mediumBlue1 w-full py-1.5 px-2 rounded-3xl cursor-pointer flex flex-col gap-1 items-center opacity-60 transition-all duration-500 ease-in-out hover:opacity-100 md:flex-row md:justify-center"
    routerLink="autoejecucion"
    routerLinkActive="bg-theme-gradient-orange !opacity-100"
    appClicks="home - autoejecucion">
    <img
      src="https://superlikers-themes.s3.amazonaws.com/modelo_para_ganar/v4/icon-img.svg"
      alt="Ícono de Autoejecución" />
    <p>Autoejecución</p>
  </a>
</footer>
