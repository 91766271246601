/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dialog, DialogModule } from '@angular/cdk/dialog'
import { AsyncPipe, NgComponentOutlet } from '@angular/common'
import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core'
import { FormGroup, ReactiveFormsModule } from '@angular/forms'
import { Router, RouterLink } from '@angular/router'
import { HttpClient } from '@angular/common/http'
import { firstValueFrom } from 'rxjs'
import { Control, ControlInjector, ControlResolver } from 'superlikers-dynamic-forms'

import { SessionService } from 'superlikers-microsites-api'
import { HelpModalComponent } from '../../components/help-modal/help-modal.component'
import { loginControls } from '@auth/data/loginForm'
import { SsoButtonComponent } from '@auth/components/sso-button/sso-button.component'

@Component({
  selector: 'app-login-page',
  standalone: true,
  imports: [DialogModule, ReactiveFormsModule, ControlInjector, NgComponentOutlet, AsyncPipe, RouterLink, SsoButtonComponent],
  templateUrl: './login-page.component.html',
  styleUrl: './login-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginPageComponent {
  http = inject(HttpClient)
  dialog = inject(Dialog)
  router = inject(Router)
  controlResolver = inject(ControlResolver)
  sessionService = inject(SessionService)

  form = new FormGroup({})
  loading = signal<boolean>(false)
  error = signal<string>('')

  controls: Control[] = loginControls

  async submit() {
    if (this.form.invalid) return

    this.loading.set(true)
    this.error.set('')

    try {
      const response$ = this.sessionService.login(this.form.value)
      const response = await firstValueFrom(response$)

      if (response.state === 'success') {
        this.router.navigateByUrl('/')
      }
    } catch (err: any) {
      if (err.error.code_error === 140) {
        this.router.navigateByUrl('/complete_signup')
      }

      const message = err.error.message || 'Ha ocurrido un error, inténtelo de nuevo'
      this.error.set(message)
    } finally {
      this.loading.set(false)
    }
  }

  openHelpModal() {
    this.dialog.open(HelpModalComponent, {
      autoFocus: 'input',
      panelClass: 'modal-custom'
    })
  }
}
