import { Control } from 'superlikers-dynamic-forms'

export const loginControls: Control[] = [
  {
    id: 1,
    label: 'Número de Cliente',
    name: 'codigo-de-cliente',
    controlType: 'input',
    type: 'text',
    validators: {
      required: true
    }
  },
  {
    id: 2,
    label: 'Contraseña',
    name: 'password',
    controlType: 'input',
    type: 'password',
    validators: {
      required: true
    }
  }
]
