/* eslint-disable @typescript-eslint/no-explicit-any */
import { AsyncPipe, NgComponentOutlet } from '@angular/common'
import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core'
import { FormGroup, ReactiveFormsModule } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { PasswordService } from '@auth/services/password.service'
import { Control, ControlInjector, ControlResolver } from 'superlikers-dynamic-forms'
import { NotificationChannel } from 'superlikers-microsites-api'

@Component({
  selector: 'app-edit-password-page',
  standalone: true,
  imports: [ReactiveFormsModule, ControlInjector, NgComponentOutlet, AsyncPipe],
  templateUrl: './edit-password-page.component.html',
  styleUrl: './edit-password-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditPasswordPageComponent {
  passwordService = inject(PasswordService)
  controlResolver = inject(ControlResolver)
  route = inject(ActivatedRoute)
  router = inject(Router)

  form = new FormGroup({})

  controls: Control[] = [
    {
      id: 1,
      label: 'Contraseña',
      name: 'password',
      controlType: 'input',
      type: 'password',
      validators: {
        required: true
      }
    },
    {
      id: 2,
      label: 'Confirmar contraseña',
      name: 'password_confirmation',
      controlType: 'input',
      type: 'password',
      validators: {
        required: true
      }
    }
  ]

  errorMessage = signal<string>('')

  loading = signal<boolean>(false)

  async submit() {
    if (this.form.invalid) return

    const token = this.route.snapshot.queryParams['token']
    const channel = this.route.snapshot.queryParams['channel'] as NotificationChannel

    const data = this.form.value as Record<string, string>

    const { password, password_confirmation } = data

    if (password !== password_confirmation) {
      this.errorMessage.set('Las contraseñas no coinciden')
      return
    }

    try {
      const response = await this.passwordService.updatePassword(password, password_confirmation, token, channel)

      if (response.state === 'success') {
        this.router.navigateByUrl('/')
      } else {
        this.errorMessage.set(response.message)
      }
    } catch (err: any) {
      this.errorMessage.set(err.error.message)
    } finally {
      this.loading.set(false)
    }
  }
}
