import { Routes } from '@angular/router'

import { LoginPageComponent } from './pages/login-page/login-page.component'
import { ResetPasswordPageComponent } from './pages/reset-password-page/reset-password-page.component'
import { CompleteSignupPageComponent } from './pages/complete-signup-page/complete-signup-page.component'
import { EditPasswordPageComponent } from './pages/edit-password-page/edit-password-page.component'

export const routes: Routes = [
  {
    path: 'login',
    component: LoginPageComponent
  },
  {
    path: 'password/new',
    component: ResetPasswordPageComponent
  },
  {
    path: 'password/edit',
    component: EditPasswordPageComponent
  },
  {
    path: 'complete_signup',
    component: CompleteSignupPageComponent
  }
]
